<template>
  <v-container id="crud" fluid tag="section" class="px-4  mx-0">
    <!--v-toolbar flat color="white">
        <v-toolbar-title>Listado de {{ title }}</v-toolbar-title>
      </v-toolbar-->
    <v-row>
      <v-col>
        <v-expansion-panels class="filtros-trans-container">
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              Filtros
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <hr>
              <br/>
              <v-row class="">
                <v-col cols="12" sm="2">
                  <v-text-field
                    v-model="filters.ref_pedido"
                    label="Ref pedido"
                    single-line hide-details
                    outlined
                    clearable
                    filled
                    dense
                    rounded
                    class="filtros-trans"
                    @click:append="clearField('ref_pedido')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select
                    v-model="filters.entidad"
                    :items="entidades"
                    label="Ente"
                    class="filtros-trans"
                    outlined
                    filled
                    dense
                    rounded
                    :append-icon="filters.entidad ? 'mdi-close' : 'mdi-menu-down'"
                    @click:append="clearEntidad"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedStartDate"
                        label="Fecha inicio"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="filtros-trans"
                        outlined
                        filled
                        dense
                        rounded
                        @click:append="clearStartDate"
                        :append-icon="filters.startDate ? 'mdi-close' : 'mdi-menu-down'"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.startDate"
                      :max="today"
                      no-title
                      scrollable
                      @input="startDateMenu = false"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedEndDate"
                        label="Fecha fin"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="filtros-trans"
                        outlined
                        filled
                        dense
                        rounded
                        @click:append="clearEndDate"
                        :append-icon="filters.endDate ? 'mdi-close' : 'mdi-menu-down'"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.endDate"
                      :max="today"
                      :min="filters.startDate"
                      no-title
                      scrollable
                      @input="endDateMenu = false"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2" class="d-flex align-center">
                  <v-btn
                    color="primary"
                    small
                    @click="applyFilters"
                      >Buscar
                    <v-icon right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" md="12" class="">
        <v-data-table
          :headers="showHeaders"
          :items="filteredData"
          :items-per-page="10"
          sort-by="DocDate"
          :sort-desc="true"
          class="elevation-0 data__table table-cell-trans"
          :footer-props="{
            'items-per-page-options': [5,10],
            'show-current-page': false,
            'show-page-count': false,
          }"
          >
          <template v-slot:no-data>
            <div v-if="loading" class="text-center">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
            </div>
            <div v-else>
              No hay datos disponibles
            </div>
          </template>
          <template v-slot:[`header.ref_pedido`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.estado`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.cliente_id`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.ente`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.total`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.metodo_pago`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.fecha`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.actions`]="{ header }">
            <span class="table-cell-trans">{{ header.text }}</span>
          </template>
          <template v-slot:[`item.ref_pedido`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ item.ref_pedido }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-row>
              <v-col>
                <div :class="$getCssTransacciones(item.estado)" class="table-cell-trans">
                  {{ item.estado }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.cliente_id`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.cliente_id }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ente`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.ente }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ $formatCurrencyValue(item.total) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.metodo_pago`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.metodo_pago }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ $formatDate(item.fecha) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center align-center">
              <div style="width: 25px; display: flex; justify-content: center;">
                  <v-icon
                    v-if="item.Drafts && item.Drafts.length > 0"
                    small color="warning" title="Ver Borrador" @click="viewFactura(item, 'borrador')">
                    mdi-file-document
              </v-icon>
              </div>
              <div style="width: 25px; display: flex; justify-content: center;">
                <v-icon
                  v-if="item.Invoices && item.Invoices.length > 0"
                  small color="primary" title="Ver Factura" @click="viewFactura(item, 'borrador')">
                  mdi-file-document
              </v-icon>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>


    <v-snackbar v-model="snackbar" :bottom="true" :color="color" :timeout="timeout">
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

function title() {
  return "Transacciones";
}
const REGISTROS_API = 10;

export default {
  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },

    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Ref Pedido", filterable: true, value: "ref_pedido", sortable: false },
      { text: "Estado", filterable: true, value: "estado", sortable: false },
      { text: "Cliente", filterable: true, value: "cliente_id", sortable: false },
      { text: "Ente", filterable: true, value: "ente", sortable: false },
      { text: "Totales", filterable: true, value: "total", sortable: false },
      { text: "Métodos de pago", filterable: true, value: "metodo_pago", sortable: false },
      { text: "Fecha", filterable: true, value: "fecha", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
      //{ text: "Tipo", filterable: true, value: "tipo", sortable: false },
      //{ text: "Entidad", filterable: true, value: "entidad", sortable: false },

    ],
    filters: {
      ref_pedido: "",
      entidad: "",
      startDate: "",
      endDate: "",
    },
    entidades: [
      {text: "ACARA", value: process.env.VUE_APP_DB_ACARA},
      {text: "MJ", value: process.env.VUE_APP_DB_MJ}
    ],
    filterKey: [],
    selectedHeaders: [],
    startDateMenu: false,
    endDateMenu: false,
    loading: false,
    transaccionesLocales: [],
    skip: 0,
    hasMoreTransacciones: true,
    currentPage: 1,
    itemsPerPage: 10,
    filtersActive: false,
    today: new Date().toISOString().substr(0, 10),
  }),

  computed: {
    ...mapState("transacciones", ["transacciones"]),
    clientCardCode() {
      const usname = this.$store.state.user.username;
      //Se harcodean usuarios de prueba
      switch (usname) {
        case "cgimenez":
          return "C33679139369";
        case "c20281037308":
          return "C20333328691";
        default:
          return usname;
      }

    },
    filteredData() {
      if (this.loading) {
        return [];
      }
      if (!this.transaccionesLocales || this.transaccionesLocales.length === 0) {
        return [];
      }
      //console.log("TRANSACCIONES FILTERDDATA LARGO "+this.transacciones.length);
      //console.log("TRANSACCIONES LOCALES FILTERDDATA LARGO "+this.transaccionesLocales.length);
      //Carga hasta 10 registros si los hubiera disponibles
      if(this.transacciones.length === REGISTROS_API && !this.filtersActive && this.transaccionesLocales.length < REGISTROS_API){
        // Si no estás en proceso de carga de datos
        if (!this.loadingData) {
          this.loadMoreData();
        }
      } else {
        console.log("Fin carga de transacciones...");
      }
      return this.transaccionesLocales;
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    formattedStartDate() {
      return this.filters.startDate ? this.$formatDate(this.filters.startDate) : "";
    },
    formattedEndDate() {
      return this.filters.endDate ? this.$formatDate(this.filters.endDate) : "";
    },
  },

  created() {
    this.selectedHeaders = this.headers;
    this.loading = true;
    const clientCardCode = this.clientCardCode;
    this.fetchTransacciones({ skip: 0, initialLoad: true, clientCardCode})
    .then(transacciones => {
      if (transacciones && transacciones.length > 0) {
        this.transaccionesLocales = [...transacciones];
        //console.log("TRANSACCIONES  EN FETCHTRANSACCIONES "+JSON.stringify(this.transacciones, null, 2));
        //console.log("TRANSACCIONESlocales  EN FETCHTRANSACCIONES "+JSON.stringify(this.transaccionesLocales, null, 2));
        this.skip = transacciones.length;
        if (transacciones.length < REGISTROS_API) {
          this.hasMoreTransacciones = false;
        }
      } else {
        this.hasMoreTransacciones = false;
      }
    })
    .finally(() => {
      this.loading = false;
    });
  },

  methods: {
    ...mapActions("transacciones", ["fetchTransacciones"]),
    ...mapMutations("transacciones", ["addTransacciones"]),

    applyFilters() {
      console.log("Apply filters transacciones...");
      const clientCardCode = this.clientCardCode;
      //console.log("TRANSACCIONESlocales applyFilters " + JSON.stringify(this.transaccionesLocales, null, 2));

      // Resetear transaccionesLocales para almacenar los nuevos resultados
      this.transaccionesLocales = [];
      //console.log("TRANSACCIONESlocales applyFilters " + JSON.stringify(this.transaccionesLocales, null, 2));
      this.loading = true;

      if (this.filters.ref_pedido) {
        // Limpiar otros filtros si ref_pedido tiene valor
        this.filters.entidad = "";
        this.filters.startDate = "";
        this.filters.endDate = "";
      }

      const loadMoreData = async (skip) => {
        const result = await this.fetchTransacciones({
          skip: skip,
          filters: this.filters,
          initialLoad: false,
          clientCardCode: clientCardCode
        });
        if (result) {
          // Guardar los nuevos resultados en transaccionesLocales
          this.transaccionesLocales = [...this.transaccionesLocales, ...result];
          //console.log("TRANSACCIONESlocales después del fetch: " + JSON.stringify(this.transaccionesLocales, null, 2));
          this.transaccionesLocales.sort((a, b) => {
            return new Date(b.fecha) - new Date(a.fecha);
          });
        } else {
          this.transaccionesLocales = this.transaccionesLocales;
        }


        // Si el resultado tiene exactamente 10 transacciones, seguir cargando
        if (result.length === REGISTROS_API) {
          console.log("Cargando más transacciones con filtros...");
          await loadMoreData(skip + REGISTROS_API);
        }
      };

      // Comenzar la carga con skip en 0
      loadMoreData(0).finally(() => {
        this.loading = false;
      });
    },
    loadMoreData() {
      if (!this.hasMoreTransacciones || this.loading) {
        return;
      }
      console.log("Cargando mas transacciones...");
      this.loading = true;
      this.fetchTransacciones({ skip: this.skip, filters: this.filters,clientCardCode: this.clientCardCode })
      .then(transacciones => {
        //console.log("Datos cargados:", JSON.stringify(transacciones, null, 2));
        if (transacciones && transacciones.length > 0) {
          this.transaccionesLocales = [...this.transaccionesLocales, ...transacciones];
          //console.log("TRANSACCIONES  EN loadMoreData "+JSON.stringify(this.transacciones, null, 2));
          //console.log("TRANSACCIONESlocales  EN loadMoreData "+JSON.stringify(this.transaccionesLocales, null, 2));
        }
        if (transacciones.length === REGISTROS_API) {
          this.skip += REGISTROS_API;
          this.loadMoreData();
        } else {
          this.hasMoreTransacciones = false;
        }
      })
      .catch(error => {
        console.error('Error en cargando mas datos:', error);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    viewDetalle(item) {
      this.$router.push({
        name: "transacciones_detalle",
        params: { transaccion_id: item.id },
      });
    },
    viewFactura(item, tipo) {
      let pdfPath = "";
      if (tipo === "definitiva") {
        pdfPath = require("@/assets/facturaAnmac.jpg");
      } else if (tipo === "borrador") {
        pdfPath = require("@/assets/facturaActivo.jpg");
      }
      window.open(pdfPath, "_blank");
    },
    filterByRefPedido(item) {
      return this.$filterBy(item, "ref_pedido");
    },
    filterByEstado(item) {
      return this.$filterBy(item, "estado");
    },
    filterByTipo(item) {
      return this.$filterBy(item, "tipo");
    },
    filterByEnte(item) {
      return this.$filterBy(item, "ente");
    },
    filterByCliente(item) {
      return this.$filterBy(item, "cliente_id");
    },
    filterByFecha(item) {
      return this.$filterBy(item, "fecha");
    },
    filterByTotal(item) {
      return this.$filterBy(item, "total");
    },
    filterByMetodoPago(item) {
      return this.$filterBy(item, "metodo_pago");
    },
    clearStartDate() {
      this.filters.startDate = "";
    },
    clearEndDate() {
      this.filters.endDate = "";
    },
    clearEntidad() {
      this.filters.entidad = null;
    },
    clearField(field) {
      this.filters[field] = "";
    },
    alert(title, msg) {
      Swal.alert(title, msg);
    },
  },
  watch: {
    'filters.ref_pedido'(newValue) {
      if (newValue) {
        this.filters.startDate = "";
        this.filters.endDate = "";
        this.filters.entidad = "";
      }
    },
    'filters.startDate'(newValue) {
      if (newValue) {
        this.filters.ref_pedido = "";
        if (this.filters.endDate && this.filters.endDate < newValue) {
          this.filters.endDate = "";
        }
      }
    },
    'filters.endDate'(newValue) {
      if (newValue) {
        this.filters.ref_pedido = "";
      }
    },
    'filters.entidad'(newValue) {
      if (newValue) {
        this.filters.ref_pedido = "";
      }
    },
  },
};
</script>

<style>
.carritoMsg__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  font-weight: bold;
  font-size: 0.85rem;
}
.carrito__div {
  overflow-y: auto;
  max-height: 41rem;
}
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
.div_total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.routerLink_total {
  background-color: #011f5b;
  color: white !important;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 0.5rem;
}

.text--primary {
  color: blue;
}
</style>
